<template lang="pug">
v-card(v-if="Object.keys(protocol).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t(`${Object.keys(protocol.behavior)[0]}-${type}`, { number: protocol.number_document }).toUpperCase() }}
      span(v-if="checkAccess('backOffice')").ml-1 (ID: {{ protocol.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end

  v-progress-linear(v-if="isLoading" indeterminate)
  v-card-text
    v-divider
    Info(v-if="protocol.behavior.viewInfoBlock" :protocol="protocol")
    FormEdit(v-else-if="protocol.behavior.viewEditBlock" :protocol="protocol")
    EditStatus(v-else-if="protocol.behavior.viewEditStatusBlock" :protocol="protocol")
    ViewPhotoList(
      v-else-if="protocol.behavior?.viewFilesBlock"
      :files="protocol.files"
      :sailorDocument="protocol"
      :documentType="type"
      :deleteFile="removeFileProtocolSQC"
      :editFile="updateFileProtocolSQC"
      isShowEdit
      )
v-progress-linear(v-else indeterminate)
</template>

<script>

import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  components: {
    Info: () => import('@/views/Sailor/SailorSQCProtocols/Info.vue'),
    FormEdit: () => import('@/views/Sailor/SailorSQCProtocols/Form.vue'),
    EditStatus: () => import('@/views/Sailor/SailorSQCProtocols/EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      type: 'sailorSQCProtocols',
      isLoading: false,
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.protocol, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.protocol, 'viewEditBlock'),
          checkAccess: () => checkAccess('sailorSQCProtocols', 'edit', this.protocol),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.protocol, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('sailorSQCProtocols', 'editStatus', this.protocol),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.protocol, 'viewFilesBlock'),
          checkAccess: () => checkAccess('sailorSQCProtocols', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('sailorSQCProtocols', 'delete', this.protocol),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => back('sqc-protocols'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['protocolByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      protocol: state => state.sailor.protocolSQCById
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getProtocolsSQCByID', 'removeProtocolsSQC', 'removeFileProtocolSQC',
      'updateFileProtocolSQC']),

    async getData () {
      await this.getProtocolsSQCByID({ ...this.$route.params })
    },

    deleteDocument () {
      deleteConfirmation().then(async confirmation => {
        if (confirmation) {
          const response = await this.removeProtocolsSQC({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response?.code)) {
            this.$notification.success('deletedDocument')
            back('sqc-protocols')
          }
        }
      })
    }
  }
}
</script>
